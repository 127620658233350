body {
  max-width: 100%;
    overflow-x: hidden;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip{
  border-width: 0px;
  border-radius: 10px;
  padding: 5px;
  height: 70px;
  min-width: 100px;
  position: absolute;
  box-shadow: 2px 2px #3c3a3a;
  background-color: white;
  text-align: center;
  font-size: 14px;
  display: inline-block;
}

.column {
  align-self: center;
  justify-self: center;
}

/* Clear floats after the columns
.row:after {
  content: "";
  display: table;
  clear: both;
  
} */
.row{

  margin: 2% 15%;
  display : grid;
  grid-template-columns : repeat(auto-fit, minmax(150px, 1fr));;
  grid-row-gap: 10%;
}

.totalHeading{
  font-size: medium;
  font-weight: bold;
}

.totalValue{
  font-size: x-large;
}

#appRoot{
  margin-top: 30px;
}

.stackedBarChart{
  overflow-x: scroll;
  overflow-y: hidden;
  width: 1000px;
}

@media (max-width: 600px){
  .row{
    margin:10% 5% 20% 5%;
  }
.confrimedValue{
  font-size: large;
}
}